<template>
  <div class="preview">
    <div class="title">我的产品</div>
    <div>
      <van-list>
        <van-cell v-for="item in tableData" :key="item.ros_id">
          <p>
            <span class="list_title">产品类型：</span>
            <span class="list_des">{{ type[item.type] }}</span>
          </p>
          <p>
            <span class="list_title">购买时间：</span>
            <span class="list_des">{{item.begin_time}}</span>
          </p>
          <p>
            <span class="list_title">到期时间：</span>
            <span class="list_des">{{item.end_time}}</span>
          </p>
          <p>
            <span class="list_title">是否续费：</span>
            <span class="list_des">
              <router-link to="/recharge">{{ item.is_active ? '续费' : '重新购买' }}</router-link>
            </span>
          </p>
        </van-cell>
      </van-list>
      <div class="nodata" v-show="havedata">暂无数据</div>
    </div>
  </div>
</template>
<script>
import { getMyProductApi } from "api/AdminCenter";
export default {
  data() {
    return {
      loading: true,
      tableData: [
        {
          begin_time: "2020-11-27 02:46:06",
          end_time: "2020-12-27 02:46:06",
          type: 3,
          is_active: true
        }
      ],
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      finishText: "",
      havedata: false
    };
  },
  mounted() {
    getMyProductApi().then(res => {
      if (res.code == 0) {
        this.tableData = res.data;
        if (res.data.length == 0) {
          this.havedata = true;
        }
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.preview {
  background: #fff;
  padding: 16px;
  padding-top: 80px;
  .title {
    padding-bottom: 35px;
    font-size: 30px;
    color: #517cfc;
  }
  p {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    .list_title {
      font-size: 16px;
      color: #7e7e7e;
    }
    .list_des {
      font-size: 16px;
      color: #202020;
    }
  }
  .van-cell {
    margin-top: 16px;
    padding: 10px 0;
  }
  .nodata {
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
  }
}
</style>